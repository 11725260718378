import "./App.css";

import React, { Fragment, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon, GiftIcon } from "@heroicons/react/24/outline";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const guestName = urlParams.get("name");

  const [open, setOpen] = useState(true);
  const [openGift, setOpenGift] = useState(false);

  const currentAudio = React.useRef(null);

  const images = [
    "/images/image-10.jpg",
    "/images/image-12.jpg",
    "/images/image-11.jpg",
    "/images/image-2.jpg",
    "/images/image-3.jpg",
    "/images/image-4.jpg",
    "/images/image-5.jpg",
    "/images/image-1.jpg",
  ];

  const [countDown, setCountDown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    const x = setInterval(function () {
      const countDownDate = new Date("Feb 4, 2024 9:0:0").getTime();
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountDown({ days, hours, minutes, seconds });
    }, 1000);

    return () => {
      if (x) {
        clearInterval(x);
      }
    };
  }, []);

  return (
    <div className="bg-[#fffceb]">
      <main className="isolate">
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#fffceb] transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
                      <div className="w-full lg:max-w-lg lg:flex-auto">
                        <h1 className="text-5xl text-center font-medium wedding-font">
                          The Wedding of
                        </h1>
                        <h2 className="wedding-font text-center text-7xl font-medium tracking-tight text-gray-900 sm:text-4xl">
                          Ihsan and Clarissa
                        </h2>

                        <p className="text-center text-xl leading-8 font-medium text-gray-800">
                          Minggu, 4 Februari 2024
                        </p>

                        <img
                          src="/images/image-0.jpg"
                          alt="Clarissa & Ihsan 1"
                          className="mt-5 w-full bg-gray-50 object-cover"
                        />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      {guestName && (
                        <div className="text-center mb-5">
                          <p className="text-sm">Kepada Yth:</p>
                          <p className="capitalize font-medium">{guestName.replace("dan", "&")}</p>
                        </div>
                      )}
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-[#d2cac5] px-3 py-2 text-white shadow-sm hover:bg-[#c5bebe] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#c5bebe]"
                        onClick={() => {
                          setOpen(false);
                          currentAudio.current.play();
                        }}
                      >
                        <EnvelopeIcon className="text-white w-6 h-6 mr-2" />
                        Open Invitation
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Content section */}
        <audio ref={currentAudio} autoPlay loop>
          <source src="/musics/everytime.mp3" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        <div className="mx-auto pt-10 max-w-7xl px-6 sm:pt-10 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
            <div className="w-full">
              <h1 className="text-5xl text-center font-medium wedding-font">
                The Wedding of
              </h1>
              <h2 className="wedding-font text-center text-7xl font-medium tracking-tight text-gray-900 sm:text-4xl">
                Ihsan and Clarissa
              </h2>
              <div className="text-center bg-white bg-opacity-60 shadow-lg mt-6 py-4">
                <p className="text-xl leading-8 font-medium text-gray-800">
                  Minggu, 4 Februari 2024
                </p>
                <p className="text-xl leading-8 font-medium text-gray-800">
                  9.00 - 13.00 WIB
                </p>
              </div>
              <img
                src="/images/image-0.jpg"
                alt="Clarissa & Ihsan 2"
                className="mt-5 aspect-[6/5] w-full bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
              />
              <div className="mt-5">
                <p className="text-center font-medium text-xl">
                  Menuju Hari Bahagia
                </p>
                <div>
                  <div className="flex gap-x-5 justify-center text-center">
                    <div>
                      <p className="fon-medium text-5xl">{countDown.days}</p>
                      <p>Hari</p>
                    </div>
                    <div>
                      <p className="fon-medium text-5xl">{countDown.hours}</p>
                      <p>Jam</p>
                    </div>
                    <div>
                      <p className="fon-medium text-5xl">{countDown.minutes}</p>
                      <p>Menit</p>
                    </div>
                    <div>
                      <p className="fon-medium text-5xl">{countDown.seconds}</p>
                      <p>Detik</p>
                    </div>
                  </div>
                </div>
                <p className="italic mt-2 text-lg text-center">
                  "Di antara tanda-tanda (kebesaran)-Nya ialah bahwa Dia
                  menciptakan pasangan-pasangan untukmu dari (jenis) dirimu
                  sendiri agar kamu merasa tenteram kepadanya. Dia menjadikan di
                  antaramu rasa cinta dan kasih sayang. Sesungguhnya pada yang
                  demikian itu benar-benar terdapat tanda-tanda (kebesaran
                  Allah) bagi kaum yang berpikir."
                </p>
                <p className="text-center italic mt-1 font-bold">
                  QS.AR-RUM:21
                </p>
              </div>
            </div>
          </div>
          <div className="mt-32">
            <div className="flex gap-x-5 items-center">
              <img
                src="/images/image-7.jpg"
                className="h-52 w-auto rounded-full shadow-lg"
                alt="Clarissa & Ihsan"
              />
              <div>
                <p className="wedding-font text-6xl">Clarissa</p>
                <p className="text-2xl font-medium">Clarissa Aulia Putri</p>
                <p className="text-md">Putri Pertama dari Bapak E. Suherli</p>
                <p className="text-md">& Ibu Imas Kurniasih</p>
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-5">
              <div className="text-right">
                <p className="wedding-font text-6xl">Ihsan</p>
                <p className="text-2xl font-medium">Ihsan Maulana Hamdani</p>
                <p className="text-md">
                  Putra Pertama dari Bapak Wawan Setiawan
                </p>
                <p className="text-md">& Ibu Lasmini Nurjanah</p>
              </div>
              <img
                src="/images/image-6.jpg"
                className="h-52 w-auto rounded-full shadow-lg"
                alt="Clarissa and Ihsan"
              />
            </div>
          </div>
        </div>

        <div className="mt-32 overflow-hidden sm:mt-40">
          <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none">
              <h2 className="wedding-font text-8xl font-medium tracking-tight text-gray-900 sm:text-4xl">
                Acara Pernikahan
              </h2>
              <div className="mb-5 text-center bg-white bg-opacity-60 shadow-lg mt-6 py-4">
                <p className="text-xl leading-8 font-medium text-gray-800">
                  Minggu, 4 Februari 2024
                </p>
                <p className="text-xl leading-8 font-medium text-gray-800">
                  9.00 - 13.00 WIB
                </p>
                <p className="text-xl leading-8 font-medium text-gray-800">
                  Kediaman Mempelai Wanita
                </p>
                <p className="text-xl leading-8 font-medium text-gray-800">
                  Kp. Ciganas, Nagrak Utara, Sukabumi, Jawa Barat.
                </p>
              </div>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3961.314627476523!2d106.79275897499588!3d-6.852838993145511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwNTEnMTAuMiJTIDEwNsKwNDcnNDMuMiJF!5e0!3m2!1sid!2sid!4v1705420213667!5m2!1sid!2sid"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mt-32 overflow-hidden sm:mt-40">
          <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
              <h2 className="wedding-font text-8xl font-medium tracking-tight text-gray-900 sm:text-4xl">
                Galeri
              </h2>
              <div className="grid grid-cols-2 gap-1">
                {images.map((el, i) => (
                  <img
                    key={i}
                    src={el}
                    alt={`Clarissa & Ihsan ${i + 10}`}
                    className="w-full object-cover h-32 sm:h-[420px]"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* UANG */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-2xl font-medium tracking-tight text-gray-900 sm:text-4xl">
              Tanpa mengurangi rasa hormat, bagi Bapak/Ibu/Saudara/i yang ingin
              mengirimkan hadiah pernikahan dapat melalui:
            </h2>
          </div>
          {!openGift ? (
            <button
              type="button"
              className="my-5 inline-flex w-full justify-center rounded-md bg-[#d2cac5] px-3 py-2 text-white shadow-sm hover:bg-[#c5bebe] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#c5bebe]"
              onClick={() => {
                setOpenGift(true);
              }}
            >
              <GiftIcon className="text-white w-6 h-6 mr-2" />
              Send Gift
            </button>
          ) : (
            <div className="mx-auto mt-5 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-5 lg:max-w-none lg:flex-row lg:items-end">
              <div className="flex flex-col justify-start items-start gap-x-16 gap-y-4 rounded-2xl bg-white bg-opacity-60 shadow-lg border border-gray-50 p-8">
                <img alt="BCA logo" src="/logos/bca.svg" className="h-10" />
                <div className="flex flex-col gap-y-1">
                  <p className="flex-none text-3xl font-bold tracking-tight">
                    3520865674
                  </p>
                  <p className="font-medium text-lg">
                    A.N : Clarissa Aulia Putri
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-x-16 gap-y-4 rounded-2xl bg-white bg-opacity-60 shadow-lg border border-gray-50 p-8">
                <img alt="Mandiri logo" src="/logos/mandiri.svg" className="h-10" />
                <div className="flex flex-col gap-y-1">
                  <p className="flex-none text-3xl font-bold tracking-tight">
                    1820002519411
                  </p>
                  <p className="font-medium text-lg">
                    A.N : Ihsan Maulana Hamdani
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-x-16 gap-y-4 rounded-2xl bg-white bg-opacity-60 shadow-lg border border-gray-50 p-8">
                <img alt="Dana logo" src="/logos/dana.svg" className="h-10" />
                <div className="flex flex-col gap-y-1">
                  <p className="flex-none text-3xl font-bold tracking-tight">
                    08121506318
                  </p>
                  <p className="font-medium text-lg">
                    A.N : Ihsan Maulana Hamdani
                  </p>
                </div>
              </div>
            </div>
          )}
          <div>
            <p className="mt-6 text-xl font-medium leading-7">Terima Kasih</p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="mt-10 sm:mt-10" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-10 lg:px-8">
          <div className="border-t border-gray-900/10 md:flex md:items-center md:justify-between">
            <p className="mt-10 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
              &copy; 4224 Ihsan & Clarissa, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
